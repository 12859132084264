var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"header",attrs:{"dense":"","flat":"","no-gutters":""}},[_c('router-link',{staticClass:"logo",attrs:{"to":{ name: 'members-list' }}},[_c('img',{attrs:{"alt":"logo","src":_vm.$store.getters['auth/user']
          ? _vm.$store.getters['auth/user'].theme_config.header_logo
          : ''}})]),(_vm.$route.name == 'members-list')?_c('v-text-field',{attrs:{"label":((_vm.$t('search')) + " " + (_vm.$t('members'))),"filled":"","dense":"","rounded":""},on:{"input":_vm.serachMembers},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"icomoon icon-search"})]},proxy:true}],null,false,3627386215)}):_vm._e(),_c('div',{staticClass:"drop-down p-0 m-0 ml-auto"},[_c('v-avatar',{class:("profile-pic\n      overflow-visible\n        messages-icon " + (_vm.$store.getters['auth/user'] &&
          _vm.$store.getters['auth/user'].theme_config.key == 'lifechamps'
            ? 'white--text'
            : '')),attrs:{"size":"48"},on:{"click":function($event){return _vm.redirect('messages')}}},[(
          _vm.unreadMessagesNum > 0 && _vm.$router.currentRoute.name !== 'messages'
        )?_c('v-icon',{staticClass:"unread-circle",attrs:{"small":""}},[_vm._v(" mdi-checkbox-blank-circle ")]):_vm._e(),_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-email ")])],1),_c('initials-avatar'),_c('v-menu',{staticClass:"ml-2 mr-2",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"chevron-down ml-2 mr-2 no-background-hover"},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',_vm._l((_vm.dropDownItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){item.link != 'logout'
              ? _vm.$router.push({ name: item.link })
              : _vm.logout()}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }