import Default from '@/layouts/Default.vue';
import i18n from '@/plugins/i18n';

let HomeRoutes = [
    {
        path: `/${ i18n.locale }`,
        redirect: {
            params: {
                lang: i18n.locale
            },
            name: 'members-list'
        }
    },
];

export default HomeRoutes;