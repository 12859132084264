import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import members from "./members";
import questionnaires from "./questionnaires";
import messages from "./messages";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  mutations: {},
  getters: {},
  actions: {
    logActivity(_, data) {
      if(!auth.state.user) return;
      data.platform = "cwa";
      this._vm.$axios.post("logs/activity", data);
    },
  },
  modules: {
    auth,
    members,
    questionnaires,
    messages,
  },
});
