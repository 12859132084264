import store from '../store'
import i18n from '@/plugins/i18n.js';

export default function pageTitleMiddleware(to) {
  // assign favicon depending on theme
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.href = store.getters['auth/user']?.theme_config.favicon ?? require('@/assets/imgs/header-logo.svg');

  // translate and capitalize browser tab title
  let title = i18n.t(to.meta.title);
	title = title.split(" ");
	title[0] = title[0].charAt(0).toUpperCase() + title[0].slice(1);
	title = title.join(" ");
	document.title = title;
}