import store from './'
import axios from '@/plugins/axios'

store.subscribe((mutation, state) =>
{
    if (mutation.type == "auth/SET_TOKEN")
    {
        if (mutation.payload)
        {
            axios.defaults.headers.common['Authorization'] = `Bearer ${ mutation.payload }`;
            return localStorage.setItem('mc_access_token', mutation.payload);
        }

        axios.defaults.headers.common['Authorization'] = null;
        return localStorage.removeItem('mc_access_token');
    }
});