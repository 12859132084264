<template>
  <v-avatar
    size="48"
    :class="`profile-pic ${
      $store.getters['auth/user'] &&
      $store.getters['auth/user'].theme_config.key == 'lifechamps'
        ? 'white--text'
        : ''
    }`"
    v-if="initials"
  >
    {{ initials }}
  </v-avatar>
</template>

<script>
export default {
  name: "InitialsAvatar",

  computed: {
    initials: function () {
      if (
        this.$store.getters["auth/user"] &&
        this.$store.getters["auth/user"]["first_name"] &&
        this.$store.getters["auth/user"]["last_name"]
      ) {
        let firstLetter =
          this.$store.getters["auth/user"]["first_name"].charAt(0);
        let secondLetter =
          this.$store.getters["auth/user"]["last_name"].charAt(0);

        return firstLetter + secondLetter;
      }

      return null;
    },
  },
};
</script>

<style
  lang="scss"
  src="@/assets/sass/components/initials-avatar.scss"
></style>
