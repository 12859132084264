<template>
  <v-row justify="center" class="position-absolute">
    <v-dialog v-model="dialog" persistent max-width="646">
      <v-card class="session-expired-dialog">
        <v-card-title>
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="p-0 justify-start d-flex mb-3">
                <v-icon color="#000" class="ml-auto mb-0 close" @click="logout">
                  icon-close
                </v-icon>
              </v-col>
              <v-col cols="12" class="p-0 justify-center d-flex">
                <img
                  alt="logo"
                  src="@/assets/imgs/lock.svg"
                  class="logo"
                  width="125"
                  height="116"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text class="m-auto">
          <h3 class="main-title">
            {{ $t("session_expired_dialog_title") }}
          </h3>
          <p class="main-body mb-0">
            <span> {{ $t("session_expired_dialog_text_1") }} </span>
            <span class="name" v-if="$store.getters['auth/user']">{{
              $store.getters["auth/user"].first_name
            }}</span>
            <span>{{ $t("session_expired_dialog_text_2") }}</span>
          </p>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-form
            v-model="valid"
            ref="form"
            class="login_form"
            @submit.prevent="submit"
          >
            <div
              class="v-input__control d-flex flex-row justify-center alert-container"
              v-if="errMsg"
            >
              <v-alert text outlined class="red-alert mb-3" height="auto">
                {{ errMsg }}
              </v-alert>
            </div>

            <v-text-field
              v-model="form.password"
              :rules="passwordRules"
              type="password"
              class="login_pass"
              :label="$t('password')"
            ></v-text-field>

            <div class="v-input__control d-flex flex-row justify-center">
              <v-btn
                type="submit"
                :disabled="!valid"
                :loading="loading"
                color="#6BC0A5"
                class="mb-0 w-100 submit-btn"
                @click="validate"
              >
                {{ $t("login") }}
              </v-btn>
            </div>
            <div class="v-input__control d-flex flex-row justify-center mt-5">
              <h4 class="cancel-btn" @click="logout">{{ $t("cancel") }}</h4>
            </div>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SessionExpiredDialog",

  data() {
    return {
      valid: false,
      loading: false,
      errMsg: null,
      form: {
        email: "",
        password: "",
      },
      passwordRules: [(v) => !!v || this.$t("password_required")],
    };
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    dialog: function() {
      return this.showDialog;
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal == true) {
        // clear check auto logout popups intervals
        this.$store.dispatch("auth/clearCheckAutoLogoutIntervals");
      } else {
        this.$refs.form.reset();
      }
    },
  },

  methods: {
    ...mapActions({
      keepSignedIn: "auth/keepSignedIn",
      logoutAction: "auth/logout",
    }),
    logout() {
      this.$emit("hideDialog");

      this.logoutAction().then(() => {
        this.$router.replace({
          name: "login",
        });
      });
    },
    submit() {
      this.loading = true;
      this.form.email = this.$store.getters["auth/user"].email;

      this.keepSignedIn(this.form)
        .then(() => {
          this.loading = false;
          this.errMsg = null;
          setTimeout(() => {
            try {
              this.$store.dispatch("logActivity", {
                tag: "login",
                type: 1,
              });

              this.$emit("hideDialog");
            } catch (error) {
              console.log(error);
            }
          }, 500);
        })
        .catch((e) => {
          this.loading = false;
          this.showLoginErrorMsg(e);
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    showLoginErrorMsg(e) {
      if (e?.response?.status == 401) {
        return (this.errMsg = this.$t("wrong_password"));
      }

      if (e?.response?.status == 429) {
        return (this.errMsg = this.$t("too_many_attempts"));
      }

      this.errMsg = this.$t("something_went_wrong");
    },
  },
};
</script>

<style
  lang="scss"
  src="@/assets/sass/components/dialogs/session-expired-dialog.scss"
></style>
