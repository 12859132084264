import Vue from "vue";
import VueEcho from "vue-echo";
import Pusher from "pusher-js";

Vue.use(VueEcho, {
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_KEY,
  secret: process.env.VUE_APP_PUSHER_SECRET,
  authEndpoint: `${process.env.VUE_APP_API_BASE_URL}broadcasting/auth`,
  host: `${process.env.VUE_APP_BASE_URL}:6001`,
  cluster: "eu",
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("mc_access_token")}`,
    },
  },
});
