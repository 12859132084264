import Auth from "@/layouts/Auth.vue";
import Default from '@/layouts/Default.vue';

let AuthRoutes = [
  {
    path: "login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue"),
    meta: {
      layout: Auth,
      title: "login",
    },
  },
  {
    path: "terms",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "terms" */ "@/views/auth/terms.vue"),
    meta: {
      auth: true,
      title: "terms",
    },
  },
  {
    path: "account-auth",
    name: "account-auth",
    component: () =>
      import(
        /* webpackChunkName: "account-auth" */ "@/views/auth/AccountAuth.vue"
      ),
    meta: {
      auth: true,
      title: "account_auth",
    },
  },
  {
    path: "my-account",
    name: "my-account",
    component: () =>
      import(
        /* webpackChunkName: "my-account" */ "@/views/auth/my-account.vue"
      ),
    meta: {
      layout: Default,
      auth: true,
      title: "my_account",
    },
  },
];

export default AuthRoutes;
