import axios from "axios";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {},

  mutations: {},

  actions: {
    getQuestionnaire({ state, commit }) {
      return axios
        .get(
          `dashboard/users/${router.currentRoute.params.id}/questionnaires/${router.currentRoute.params.questionnaire}`
        )
        .then(({ data }) => data.data);
    },
  },
};
