<template>
  <v-app id="app">
    <component v-bind:is="'style'" type="text/css" lang="scss">
      :root { --primary-color:
      {{ this.themeConfig ? this.themeConfig.primary_color : "#6bc0a5" }};
      --secondary-color:
      {{ this.themeConfig ? this.themeConfig.secondary_color : "#A9EAD5" }};
      --avatar-main-colors:
      {{ this.themeConfig ? this.themeConfig.avatar_main_colors : "#D3ECE4" }};
      --btn-text-color:
      {{ this.themeConfig ? this.themeConfig.btn_text_color : "#004447" }};
      --search-bar-color:
      {{ this.themeConfig ? this.themeConfig.search_bar_color : "#F0F9F6" }};
      --card-background:
      {{ this.themeConfig ? this.themeConfig.card_background : "#F8FFFD" }};
      --card-border:
      {{ this.themeConfig ? this.themeConfig.card_border : "#7AC6AE" }};
      --table-headers-main-color:
      {{
        this.themeConfig
          ? this.themeConfig.table_headers_main_color
          : "#007C82"
      }}; --progress-bar-primary-color:
      {{
        this.themeConfig
          ? this.themeConfig.progress_bar_primary_color
          : "#F09267"
      }}; --progress-bar-secondary-color:
      {{
        this.themeConfig
          ? this.themeConfig.progress_bar_secondary_color
          : "#007C82"
      }}; }
    </component>

    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>

    <CheckAutoLogoutDialog
      :showDialog="showAutoLogoutDialog"
      @hideDialog="showAutoLogoutDialog = false"
    />
    <SessionExpiredDialog
      :showDialog="showSessionExpirationDialog"
      @hideDialog="showSessionExpirationDialog = false"
    />
  </v-app>
</template>

<script>
import CheckAutoLogoutDialog from "@/components/dialogs/CheckAutoLogoutDialog.vue";
import SessionExpiredDialog from "@/components/dialogs/SessionExpiredDialog.vue";

export default {
  data() {
    return {
      showAutoLogoutDialog: false,
      showSessionExpirationDialog: false,
    };
  },

  computed: {
    themeConfig: function () {
      return this.$store.getters["auth/user"]
        ? this.$store.getters["auth/user"].theme_config
        : null;
    },
  },

  components: {
    CheckAutoLogoutDialog,
    SessionExpiredDialog,
  },

  watch: {
    themeConfig(val) {
      if (val.main_font) {
        document.documentElement.style.setProperty(
          "--main-font",
          this.themeConfig.main_font
        );
      }
    },
  },

  mounted() {
    this.handleLangSwitching();
    this.runChecks();
  },

  updated() {
    this.handleLangSwitching();
    this.runChecks();
  },

  methods: {
    handleLangSwitching() {
      this.switchBreadcrumbsLang();
      this.switchVuetifyLang();
    },
    switchBreadcrumbsLang() {
      this.$route?.meta?.breadcrumbs?.map((breadcrumb) => {
        breadcrumb.translatedText = this.$i18n.t(breadcrumb.text);

        let breadcrumbWords = breadcrumb.href?.split("/");
        if (breadcrumbWords) {
          if (this.$i18n.locale != breadcrumbWords[1]) {
            breadcrumbWords[1] = this.$i18n.locale;
            breadcrumb.href = breadcrumbWords.join("/");
          }
        }
      });
    },
    switchVuetifyLang() {
      if (this.$vuetify.lang.current != this.$i18n.locale) {
        this.$vuetify.lang.current = this.$i18n.locale.split("_")[0];
      }
    },
    checkAutoLogout(storeAction, endpoint, callBack) {
      this.$store.dispatch(
        storeAction,
        setInterval(async () => {
          const res = await this.$axios.get(endpoint);
          if (res?.data?.data?.is_timeOut) {
            callBack();
          }
        }, 60000)
      );
    },
    runChecks() {
      if (
        this.$store.getters["auth/user"] &&
        this.$store.getters["auth/user"].enable_auto_logout &&
        !this.$store.getters["auth/checkAutoLogoutIntervID"] &&
        !this.$store.getters["auth/checkSessionExpirationIntervID"] &&
        this.showAutoLogoutDialog == false &&
        this.showSessionExpirationDialog == false
      ) {
        this.checkAutoLogout(
          "auth/setCheckAutoLogoutIntervalID",
          "dashboard/users/check-auto-logout",
          () => {
            this.showAutoLogoutDialog = true;
          }
        );

        this.checkAutoLogout(
          "auth/setCheckSessionExpirationIntervalID",
          "dashboard/users/check-auto-logout/token-expiration",
          () => {
            this.showSessionExpirationDialog = true;
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/style.scss"></style>
