<template>
  <v-toolbar dense flat no-gutters class="header lifechamps-header">
    <router-link :to="{ name: 'members-list' }" class="logo">
      <img
        alt="logo"
        :src="
          $store.getters['auth/user']
            ? $store.getters['auth/user'].theme_config.header_logo
            : ''
        "
      />
    </router-link>

    <h2 class="main-title">Patient-level</h2>

    <v-text-field
      :label="`${$t('search')} ${$t('members')}`"
      filled
      dense
      rounded
      class="m-0"
      @input="serachMembers"
      v-if="$route.name == 'members-list'"
    >
      <template v-slot:prepend-inner>
        <span class="icomoon icon-search"></span>
      </template>
    </v-text-field>
  </v-toolbar>
</template>

<script>
export default {
  methods: {
    serachMembers(e) {
      this.$store.dispatch("members/searchMembers", e);
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/header.scss"></style>
